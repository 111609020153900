export default {
  'testimony1': 'B2A solutions is really awesome',
  'author1': 'Richard Ben Aleya',
  'position1': 'Owner @ Scalable Solutions',
  'testimony2': 'B2A solutions helped me achieve my goals by providing guidance and support for my digital presence',
  'author2': 'Nikolett Bencze',
  'position2': 'Photographer',
  'testimony3': 'The training provided by B2A solutions about React was amazing. I enjoyed every minute of it and I could directly after the training start coding using best practices',
  'author3': 'Jhon doe',
  'position3': 'Developer @ ingenico',
  'award_won': 'Trainings provided',
  'employees': 'International experts',
  'clients': 'Happy clients',
  'projects': 'Achieved projects',
}
