export default {
  'headquarters' : 'Headquarters',
  'working_hours' : 'Fri-mon: 8->18',
  'email' : 'Email',
  'phone' : 'Phone',
  'city' : 'Brussels - Belgium',
  'keep_in_touch' : 'Let\'s keep in touch',
  'message' : 'Message:',
  'last_name' : 'Last name:',
  'email_contact' : 'Email:',
  'phone_contact' : 'Phone:',
  'first_name' : 'First name:',
  'contact_now' : 'Contact us',
  'first_name.errorMessage': 'First name is required',
  'last_name.errorMessage': 'Last name is required',
  'email.errorMessage': 'Email is required',
  'message.errorMessage': 'Message is required',
  'email.invalidEmail': 'Email is invalid',
  'send_success': 'Your message has been sent successfully !',
  'send_error': 'There was an issue while sending your message',
}
