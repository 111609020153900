import flatten from 'flat';

import error404 from './404';
import navigation from './navigation';
import header from './header';
import about from './about';
import customers from './customers';
import jobs from './jobs';
import contact from './contact';

const messages = { error404, navigation, header, about, customers, jobs, contact };

export default flatten(messages);
