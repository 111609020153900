import React from 'react'

export default function Footer() {
  return (
    <section className="bg-light text-center">
      <h2 className="d-none">hidden</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="footer-social">
              <ul className="list-unstyled">
                <li>
                  <a className="wow fadeInUp" href="https://www.facebook.com/B2Asolutions/">
                    <i className="fa fa-facebook" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a className="wow fadeInDown" href="https://twitter.com/B2A_solutions">
                    <i className="fa fa-twitter" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a className="wow fadeInDown" href="https://www.linkedin.com/company/b2asolutions/">
                    <i className="fa fa-linkedin" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a className="wow fadeInUp" href="https://www.instagram.com/b2a_solutions/">
                    <i className="fa fa-instagram" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a className="wow fadeInDown" href="mailto:director@b2asolutions.com">
                    <i className="fa fa-envelope-o" aria-hidden="true" />
                  </a>
                </li>
              </ul>
            </div>
            <p className="company-about fadeIn">
              © 2019 B2A solutions. Made with <i className="fa fa-heart" aria-hidden="true" style={{ color: '#E22B2D' }} /> By <a href="https://www.b2asolutions.com">B2A solutions</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
