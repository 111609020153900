import React from 'react';

export function Loader() {
  return (
    <div className="loader">
      <div className="loader-inner">
        <div className="loader-blocks">
          <span className="block-1" />
          <span className="block-2" />
          <span className="block-3" />
          <span className="block-4" />
          <span className="block-5" />
          <span className="block-6" />
          <span className="block-7" />
          <span className="block-8" />
          <span className="block-9" />
          <span className="block-10" />
          <span className="block-11" />
          <span className="block-12" />
          <span className="block-13" />
          <span className="block-14" />
          <span className="block-15" />
          <span className="block-16" />
        </div>
      </div>
    </div>
  )
}
