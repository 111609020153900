export default {
  'b2a_solutions_welcome': 'Welcome to B2A solutions\'s website',
  'it_consulting_company': 'IT consulting company',
  'located_in_brussels': 'Located in the ♡ of Europe',
  'your_one_click_away': 'You are one click away from turning your dream into reality',
  'we_design_scalable': 'We design and build scalable, user-friendly software and solutions',
  'learn_more': 'Learn more',
  'b2asolutions': '-- B2A SOLUTIONS --',
  'the_best_solution': 'Best solution for your ideas',
  'highly_skilled': 'Highly skilled professionals that meet your needs',
  'team_experts': 'International experts',
  'located_brussels': 'Located in Brussels',
  'dreams_reality': 'Turn your dream into reality with our team of experts',
}
