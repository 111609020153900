import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Logo from '../Logo';

const Header = ({ intl, notFoundPage }) => {
  return (
    <header className="cursor-light">
      <nav className="navbar navbar-top-default navbar-expand-lg navbar-gradient nav-icon">
        <div className="container">
          <Link to="/" title="Logo" className="logo link">
            <Logo />
          </Link>

          {!notFoundPage && (
            <>
              <div className="collapse navbar-collapse" id="wexim">
                <div className="navbar-nav ml-auto">
                  <a className="nav-link link scroll" href="#home">{intl.formatMessage({ id: 'navigation.home' })}</a>
                  <a className="nav-link link scroll" href="#about">{intl.formatMessage({ id: 'navigation.about' })}</a>
                  <a className="nav-link link scroll" href="#jobs">{intl.formatMessage({ id: 'navigation.jobs' })}</a>
                  {/*<a className="nav-link link scroll" href="#events">{intl.formatMessage({ id: 'navigation.events' })}</a>*/}
                  <a className="nav-link link scroll" href="#contact">{intl.formatMessage({ id: 'navigation.contact' })}</a>
                  <span className="menu-line"><i className="fa fa-angle-down" aria-hidden="true" /></span>
                </div>
              </div>

              <button
                type="button"
                className="d-inline-block parallax-btn sidemenu_btn"
                id="sidemenu_toggle"
                style={{
                  backgroundColor: 'transparent',
                  border: 0,
                  cursor: 'pointer'
                }}
              >
                <div className="animated-wrap sidemenu_btn_inner">
                  <div className="animated-element">
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </button>
            </>
          )}


        </div>
      </nav>

      {!notFoundPage && (
        <>
          <div className="side-menu">
            <div className="inner-wrapper">
              <span className="btn-close link" id="btn_sideNavClose" />
              <nav className="side-nav w-100">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link link scroll" href="#home">{intl.formatMessage({ id: 'navigation.home' })}</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link link scroll" href="#about">{intl.formatMessage({ id: 'navigation.about' })}</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link link scroll" href="#jobs">{intl.formatMessage({ id: 'navigation.jobs' })}</a>
                  </li>
                  {/*<li className="nav-item">
                    <a className="nav-link link scroll" href="#events">{intl.formatMessage({ id: 'navigation.events' })}</a>
                  </li>*/}
                  <li className="nav-item">
                    <a className="nav-link link scroll" href="#contact">{intl.formatMessage({ id: 'navigation.contact' })}</a>
                  </li>
                </ul>
              </nav>

              <div className="side-footer text-white w-100">
                <ul className="social-icons-simple">
                  <li className="animated-wrap">
                    <a className="animated-element" href="https://www.facebook.com/B2Asolutions/">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li className="animated-wrap">
                    <a className="animated-element" href="https://www.instagram.com/b2a_solutions/">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li className="animated-wrap">
                    <a className="animated-element" href="https://twitter.com/B2A_solutions">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li className="animated-wrap">
                    <a className="animated-element" href="https://www.linkedin.com/company/b2asolutions/">
                      <i className="fa fa-linkedin" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
                <p className="text-white">&copy; {new Date().getFullYear()} B2A solutions. Made With Love by B2A solutions</p>
              </div>
            </div>
          </div>
          <button type="button" id="close_side_menu" />
        </>
      )}

    </header>
  );
}

Header.propTypes = {
  intl: PropTypes.object,
  notFoundPage: PropTypes.bool
};

export default injectIntl(Header);
