export default {
  'about_us': 'Who are we',
  'it_consulting_company': 'IT consulting company specialised in Apps and Web development',
  'company_explanation': 'B2A solutions is a company with highly skilled persons eager to provide you with a solution tailored to your needs. We design, build, maintain and make your solutions evolve and grow along with your business. We also guide you throughout the growth of your business so that you can focus on your passion.',
  'web_mobile_apps': 'Web and Mobile apps',
  'web_mobile_apps_explanation': 'Have an idea? Found a way to optimize your business? we are here to help you detect and formalize your real needs and provide you with the most suitable solution.',
  'digital_strategy': 'Digital strategy',
  'digital_strategy_explanation': 'Need help with managing your digital presence and your social networks? We offer guidance and help you manage your digital strategy to increase the impact of your communication.',
  'consulting_trainings': 'Consulting and Trainings',
  'consulting_trainings_explanation': 'Need help on an IT project? Need a training on new technologies that will increase your team efficiency? We provide trainings by international experts and put technical specialist at your disposal.',
  'reliable_app': 'Reliable and Scalable apps',
  'app_development': 'Web and mobile apps development',
  'app_development_explanation': 'Analyse, design, build and maintain scalable, fast-growing and user-friendly software and solutions that are perfectly adapted to your need',
  'fast': 'Blazing fast',
  'fast_explanation': 'Speed past the competition with our apps and make them ready to be delivered instantly to your users wherever they are',
  'clean_code': 'Powerful code',
  'customized': 'Customized',
  'responsive': 'Responsive',
  'responsive_explanation': 'Our products are tested on multiple platforms and devices in order to make sure your users get the best experience',
  'customized_explanation': 'As you are the main focus of our mission, we will provide precise services tailored to your needs',
  'clean_code_explanation': 'Clean and clear code ready to be reused, adjusted and maintained',
}
